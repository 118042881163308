<template>
	<div>
		<div class="d-flex justify-content-between my-6">
			<div class="ml-3">
				<h4>Alert Banners</h4>
				<span style="color: grey">Shows a message banner at the top of every page for users with devices on a selected network </span>
			</div>
			<div>
				<b-button variant="success" @click="openAlertModal()">Add New Alert</b-button>
			</div>
		</div>
		<ITCSpinner :loaded="loaded">
			<b-table :fields="fields" :items="data" show-empty hover tbody-tr-class="align-middle">
				<template #empty>
					<div class="text-center mt-15">
						<h1 class="font-weight-light">No Alerts Currently Set</h1>
					</div>
				</template>
				<template #cell(actions)="data">
					<div class="d-flex justify-content-center">
						<div>
							<span class="btn btn-icon btn-light btn-sm mx-3" v-b-tooltip.hover="'Edit'" @click="openAlertModal(data.item)">
								<span class="svg-icon svg-icon-md svg-icon-info">
									<inline-svg src="/media/svg/icons/Communication/Write.svg"></inline-svg>
								</span>
							</span>
						</div>
						<div>
							<span class="btn btn-icon btn-light btn-sm mx-3" v-b-tooltip.hover="'Delete'" @click="deleteAlert(data.item, data.index)">
								<span class="svg-icon svg-icon-md svg-icon-danger delete-icon">
									<inline-svg src="/media/svg/icons/General/Trash.svg"></inline-svg>
								</span>
							</span>
						</div>
					</div>
				</template>
			</b-table>
		</ITCSpinner>
		<b-modal
			id="newAlert"
			title="Create New Alert"
			@ok="saveAlert"
			@cancel="resetForm"
			ok-title="Save"
			:ok-disabled="!form.ExpirationDate || !form.Message"
		>
			<div class="mb-10">
				<span style="color: grey;">Alert banners will display across the top of the page, above the Marlink logo and user icon</span>
			</div>
			<b-form-group label="Message" label-for="message">
				<b-form-input type="text" id="message" v-model="form.Message" />
			</b-form-group>
			<div class="row">
				<div class="col-6">
					<b-form-group label="Expiration Date" label-for="expiration">
						<b-form-datepicker
							type="text"
							id="expiration"
							v-model="form.ExpirationDate"
							:date-format-options="{ year: 'numeric', month: 'short', day: '2-digit' }"
						/>
					</b-form-group>
				</div>
				<div class="col-6">
					<b-form-group label="Expiration Time (UTC)" label-for="expirationTime">
						<b-form-timepicker :hour12="false" id="expirationTime" v-model="form.ExpirationTime" />
					</b-form-group>
				</div>
			</div>
			<div>
				<h5>
					Select Networks by Teleport <span v-if="!netsLoaded"><b-spinner /></span>
				</h5>
				<hr />
				<div v-for="(network, teleport) in networks" :key="teleport" class="mt-3">
					<h5>{{ teleport }}</h5>
					<div v-for="n in network" :key="n.ServerId + '' + n.NetworkId">
						<b-form-checkbox v-model="form.Networks[n.ServerId][n.NetworkId]" @change="getNetworkNames(n)" switch>
							{{ n.NetworkName }}
						</b-form-checkbox>
					</div>
				</div>
			</div>
		</b-modal>
	</div>
</template>

<script>
export default {
	name: 'AlertBanners',
	components: {
		ITCSpinner: () => import('@/view/content/loaders/itcSpinner.vue'),
	},
	data() {
		return {
			loaded: false,
			netsLoaded: false,
			data: null,
			fields: [
				{ key: 'network_names', label: 'Networks', tdClass: 'align-middle' },
				{ key: 'Message', label: 'Message', tdClass: 'align-middle' },
				{ key: 'Expiration', label: 'Expiration Time (UTC)', tdClass: 'align-middle' },
				{ key: 'Username', label: 'Created By', tdClass: 'align-middle' },
				{ key: 'actions', thClass: 'text-center', tdClass: 'align-middle' },
			],
			form: {
				Message: null,
				ExpirationDate: null,
				ExpirationTime: '00:00',
				Networks: {},
				NetworkNames: {},
			},
			networks: null,
			servers: null,
		};
	},
	methods: {
		async openAlertModal(alert = null) {
			if (!this.netsLoaded) {
				let resp = await this.$http.get('/alertbanners/networks');
				this.networks = resp.data.data.networks;
				this.servers = resp.data.data.servers;
				this.netsLoaded = false;
				this.servers.forEach(s => {
					this.form.Networks[s.server_id] = {};
					this.form.NetworkNames[s.server_id] = {};
				});
				this.netsLoaded = true;
			}
			if (alert) {
				this.form.MessageId = alert.MessageId;
				this.form.Message = alert.Message;
				let dateArr = alert.Expiration.split(' ');
				this.form.ExpirationDate = dateArr[0];
				this.form.ExpirationTime = dateArr[1];
				alert.networks.forEach(net => {
					this.form.Networks[net.ServerId][net.NetworkId] = true;
					Object.values(this.networks).forEach(teleportNets => {
						let foundNetwork = teleportNets.find(n => n.ServerId == net.ServerId && n.NetworkId == net.NetworkId);
						if (foundNetwork) {
							this.form.NetworkNames[net.ServerId][net.NetworkId] = foundNetwork.NetworkName;
						}
					});
				});
			}
			this.$bvModal.show('newAlert');
		},
		getNetworkNames(network) {
			if (this.form.NetworkNames[network.ServerId]?.[network.NetworkId]) {
				delete this.form.NetworkNames[network.ServerId][network.NetworkId];
			} else {
				this.form.NetworkNames[network.ServerId][network.NetworkId] = network.NetworkName;
			}
		},
		saveAlert() {
			let payload = {
				Message: this.form.Message,
				Expiration: this.form.ExpirationDate + ' ' + this.form.ExpirationTime,
				Networks: this.form.Networks,
				NetworkNames: this.form.NetworkNames,
			};
			let url = '/alertbanners';
			if (this.form.MessageId) {
				url = `/alertbanner/${this.form.MessageId}`;
				payload.MessageId = this.form.MessageId;
			}
			this.$http.post(url, payload).then(resp => {
				this.resetForm();
				this.loadAlerts();
			});
		},
		deleteAlert(data, index) {
			this.$bvModal
				.msgBoxConfirm('Delete alert ' + data.network_names + '?', {
					title: 'Delete',
					size: 'sm',
					okVariant: 'danger',
					okTitle: 'YES',
					cancelTitle: 'NO',
					footerClass: 'p-2',
				})
				.then(val => {
					if (val) {
						this.$http.delete(`/alertbanner/${data.MessageId}`).then(resp => {
							if (resp.data.data.status == 200) this.data.splice(index, 1);
						});
					}
				});
		},
		resetForm() {
			this.servers.forEach(s => {
				this.form.Networks[s.server_id] = {};
				this.form.NetworkNames[s.server_id] = {};
			});
			this.form.Message = null;
			this.form.ExpirationDate = null;
			this.form.ExpirationTime = '00:00';
		},
		loadAlerts() {
			this.loaded = false;
			this.$http.get('/alertbanners').then(resp => {
				this.data = Object.values(resp.data.data);
				this.loaded = true;
			});
		},
	},
	created() {
		this.loadAlerts();
	},
};
</script>

<style scoped>
.align-middle {
	vertical-align: middle;
}
.delete-icon:hover {
	color: red;
	cursor: pointer;
}
</style>
